<template>
  <cw-page
    icon="check"
    class="cw-business-loan-signing-pending"
  >
    <template #hero-title>
      <translate>
        Thank You for signing
      </translate>
    </template>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <div
          class="
            cw-border-container
            cw-border-container--primary
          "
        >
          <p>
            <translate>
              For your part, the loan application is signed.
            </translate>
          </p>
          <p>
            <translate>
              The loan agreement can be accepted and the loan paid into the company account after
              the signatories of the company name-signing clause have visited to confirm the loan
              application.
            </translate>
          </p>
        </div>
      </v-col>

      <v-col
        md="6"
        cols="12"
      >
        <v-alert
          border="left"
          class="mb-0"
          color="primary"
          text
        >
          <v-row>
            <v-col>
              <h3 class="text-subtitle-1 font-weight-bold mb-1">
                <translate>The business signature clause</translate>
              </h3>
              <p class="grey--text text--darken-2">
                {{ signingRights.procurationAbstractDescription }}
              </p>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CwBusinessLoanSigningPending',

  computed: {
    ...mapState({
      signingRights: state => state.application.signingRights,
    }),
  },

  async mounted() {
    await this.getSigningRights();
  },

  methods: {
    ...mapActions({
      getSigningRights: 'application/getSigningRights',
    }),
  },
};
</script>
